import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import { PageModules }  from '@components/PageModules'

const Page = ({ data, pageContext }) => {
  const { modules } = data.page
  const seo = {
    title: "nightcap",
    description: "Let's get to work. Together.",
    ogImage: { 
      fixed: {
        width: '1280px',
        height: '808px',
        src: '//nightcap.net/images/nightcap_og_share.jpg',
      }
    }
  }

  return (
    <Layout seo={seo}>
      <PageModules modules={modules} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Page

export const query = graphql`
  query Page($id: String!, $locale: String!) {
    page: contentfulPage(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      title
      slug
      modules {
        # ...moduleStory
        # ...moduleCarousel
        # ...moduleText
        ...modulePlaceholder
        # ...moduleImage
      }
    }
  }
`
